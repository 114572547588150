import React, { Component } from 'react';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Herosection from './sections/herosection.js';
import About from './sections/about.js';
import TechLogos from './sections/techlogos.js';
import Approach from './sections/approach.js';
import FaqList from './sections/faq.js';
import ProjectList from './sections/projects.js';

class Home extends Component {
  constructor(props)
  {
      super(props)
  }
  componentDidMount() {
    const element = document.getElementById(location.hash.replace("#", ""));
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (element && window.navigator.userAgent.indexOf('Trident/') <= 0) element.scrollIntoView();
    }, 100);
  }
    render() {
        return (
            <div>
            <Header />
            <Herosection />
            <div className="page-content">
              <TechLogos logoitems={6}  />
              <About />
              <Approach />
              <ProjectList />
              <FaqList />
            </div>
            <Footer />
          </div>
        );
    }
}

export default Home;