import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ContactForm from '../../widgets/contact';

class Footer extends Component {
    render() {
        return (
            <footer className="py-11 bg-primary position-relative">
                <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                </svg>
                </div>
                <Container id="contact" className="mt-11">
                    <Row>
                        <div className="col-lg-4 col-md-12">
                            <h4 className="text-white">Onshore:</h4>
                            <div className="row mb-5 text-white">
                                <div className="col-2">
                                    <i className="la la-map-marker"></i>
                                </div>
                                <div className="col-10">
                                    <span>1500 Poly Dr, Suite 107</span><br/>
                                    <span>Billings, MT, USA</span>
                                </div>
                                <div className="col-2">
                                    <i className="la la-envelope"></i>
                                </div>
                                <div className="col-10">
                                    <a href="mailto:tom@whitewater.dev" className="text-white">tom@whitewater.dev</a><br/>
                                </div>
                                <div className="col-2">
                                    <i className="la la-phone"></i>
                                </div>
                                <div className="col-10">
                                    <a href="tel:+14066728292" className="text-white">+1-406-672-8292</a>
                                </div>
                            </div>
                            <h4 className="mt-5 text-white">Offshore:</h4>
                            <div className="row mb-5 text-white">
                                <div className="col-2">
                                    <i className="la la-map-marker"></i>
                                </div>
                                <div className="col-10">
                                    <span>SCO, 365, Second Floor</span><br/>
                                    <span>Sector 44D</span><br/>
                                    <span>Chandigarh, India</span>
                                </div>
                                <div className="col-2">
                                    <i className="la la-envelope"></i>
                                </div>
                                <div className="col-10">
                                    <a href="mailto:dinesh@whitewater.dev" className="text-white">dinesh@whitewater.dev</a><br/>
                                </div>
                                <div className="col-2">
                                    <i className="la la-phone"></i>
                                </div>
                                <div className="col-10">
                                    <a href="tel:+917696802319" className="text-white">+91 76968 02319</a>
                                </div>
                            </div>
                            <div className="row mb-5 text-white">
                                <div className="col-12">
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item"><a className="text-light ic-2x" target="_blank" href="https://facebook.com/whitewatertech"><i className="la la-facebook" /></a></li>
                                      <li className="list-inline-item"><a className="text-light ic-2x" target="_blank" href="https://www.linkedin.com/company/whitewater-tech"><i className="la la-linkedin" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="row justify-content-center mb-5 text-center">
                                <div className="col-12 col-lg-8">
                                    <div className="text-white">
                                        <h2 className="mt-0 mb-0">Let's Talk!</h2>
                                        <p className="lead mb-0 text-white">Get in touch and let us know how we can help.</p>
                                    </div>
                                </div>
                            </div>
                            <ContactForm />
                        </div>
                    </Row>
                    <Row className="text-white text-center mt-8">
                        <Col>
                        <hr className="mb-8" />&copy; {new Date().getFullYear()} Whitewater Tech. All Rights Reserved.</Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default Footer;