import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import InnerPage from '../../layout/innerpage';
import ProjectPortfolio from '../../api/ProjectPortfolio.js';

class Projects extends Component {
    constructor(props) {
      super(props);
      this.state = {

      }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const content = ProjectPortfolio.filter((p) => p.url === [this.props.match.params.content][0])[0];
        const img = "<picture style='float:right; margin-left:20px; margin-top:20px; max-width:50%; overflow:hidden'>"
                      + "<source type='image/webp' srcset=" + require('../../assets/images/portfolio/' + content.imageWebp) + " />"
                      + "<source type='image/jpeg' srcset=" + require('../../assets/images/portfolio/' + content.images) + " />"
                      + "<img className='img-fluid d-inline mb-5' src=" + require('../../assets/images/portfolio/' + content.images) + " alt='' />"
                    + "</picture>";

        const linkToMVPPage = "<p>Do you have an MVP project in the hopper? Whitewater Tech specializes in MVP development. <a href='/mvpdevelopment'>Read about it here.</a></p>";
        const linkToStartupsPage = "<p>Whitewater Tech loves working with startups and has special programs for startups working with accelerators and incubators. <a href='/accelerators'>Read about programs for startups here.</a></p>";
                
        const getProjectHeadline = () => {
          let headline = content.description.split('.')[0];
          return headline.replace('<p>', '');
        }

        const getProjectDescription = () => {

          let projectContent = img 
              + content.description 
              + (content.category.indexOf('MVP') >= 0 ? linkToMVPPage : "")
              + (content.category.indexOf('Startups') >= 0 ? linkToStartupsPage : "")
              + (content.tech ? "<h3>Tech Stack</h3>" + content.tech : "");
      
          return {
            __html: projectContent
          }
        }

        const handleBackClick = (evt) => {
          if (this.props.history.length > 2) {
            evt.preventDefault();
            this.props.history.goBack();
          }
        }

        return (
          <div>
            <Helmet>
              <title>Whitewater Tech Projects - {content.name}</title>
              <meta name="description" content={getProjectHeadline()} />
            </Helmet>
            {/*header start*/}
            <Header />
            {/*header end*/}
            <InnerPage />
            {/*body content start*/}
            <div className="page-content">
              <section>
                  <div className="container">
                      <div className="row">
                          <div className="col-12">
                            {/*TODO: Now make this look good!*/}
                            <h1>{content.name}</h1>
                            <h6>Project Categories: {content.category.sort().join(', ')}</h6>
                            <div dangerouslySetInnerHTML={getProjectDescription()}></div>
                            <Link className="btn btn-primary mt-6" to="/#portfolio" onClick={handleBackClick}>Back</Link>
                          </div>
                      </div>
                  </div>
              </section>
            </div>
          {/*body content end*/}
          {/*footer start*/}
          <Footer />
          {/*footer end*/}
          </div>
        );
    }
}

export default Projects;