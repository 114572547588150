import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';  
window.fn = OwlCarousel;

class TechLogos extends Component {
    state= {
        responsive:{
            0: {
                items: 2,
            },
            450: {
                items: 3,
            },
            900: {
                items: 4,
            },
            1200: {
                items: 5,
            },
        },
    }

    render() {
        return (
          // <div className="owl-carousel mt-8 no-pb" data-dots="false" data-items={4} data-md-items={4} data-sm-items={3} data-xs-items={2} data-margin={30} data-autoplay="true">
          <section className="p-0">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <OwlCarousel
                            className={`owl-carousel mt-${this.props.margintop} no-pb`}
                            dotData={false}
                            items={this.props.logoitems}
                            autoplay={true}
                            margin={30}
                            dots={false}
                            loop={true}
                            responsive={ this.state.responsive }
                        >
                        <div className="item">
                            <div className="tech-logo">
                                <img className="img-fluid" src={require(`../../../assets/images/tech/react.png`)} alt="ReactJS logo" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="tech-logo">
                                <img className="img-fluid" src={require(`../../../assets/images/tech/angular.png`)} alt="Angular logo" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="tech-logo">
                                <img className="img-fluid" src={require(`../../../assets/images/tech/msnetcore.png`)} alt=".NET Core logo" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="tech-logo">
                                <img className="img-fluid" src={require(`../../../assets/images/tech/nodejs.png`)} alt="NodeJS logo" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="tech-logo">
                                <img className="img-fluid" src={require(`../../../assets/images/tech/bootstrap.png`)} alt="Bootstrap logo" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="tech-logo">
                                <img className="img-fluid" src={require(`../../../assets/images/tech/azure.png`)} alt="Microsoft Azure logo" />
                            </div>
                        </div>
                        </OwlCarousel> 
                </div>
              </div>
            </div>
          </section>

        );
    }
}

export default TechLogos;