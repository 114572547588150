import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';  
window.fn = OwlCarousel;

class Approach extends Component {
    state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            900: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
    }

    render() {
        return (
          <section className="position-relative bg-dark parallaxie">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8">
                  <div id="startups"></div>
                  <div> 
                    <span className="badge badge-orange p-2">
                      <i className="la la-rocket ic-3x heartbeat" />
                    </span>
                    <h2 className="mt-4 text-white">Our Total Startup Package</h2>
                    <p className="lead mb-0">If you're just getting started, we can help you go from prototype to MVP to scaling up for growth.</p>
                  </div>
                </div>
              </div>
              {/* / .row */}
            </div>
            <div className="container-fluid text-center">
              <div className="row justify-content-center">
                <div className="col-12">
                  {/* <div className="owl-carousel owl-center" data-center="true" data-items={3} data-md-items={3} data-sm-items={1}> */}
                  <OwlCarousel
                      className="owl-carousel owl-center mb-5"
                      center={true}
                      dots={true}
                      items={3}
                      autoplay={true}
                      loop={true}
                      responsive={ this.state.responsive }
                  >
                    <div className="item">
                      <div className="card p-5 p-lg-8 bg-primary-soft border-0">
                        <div>
                          <span className="text-white p-2">
                            <i className="la la-dice-one ic-5x" />
                          </span>
                        </div>
                        <div className="card-body p-0">
                          <h5 className="text-primary d-inline mb-0">Scoping / Planning</h5>
                          <p className="text-light">Let's talk. What is your business? Who is your market? What is your core value proposition? What do we need to build?</p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="card p-5 p-lg-8 bg-primary-soft border-0">
                        <div>
                          <span className="text-white p-2">
                            <i className="la la-dice-two ic-5x" />
                          </span>
                        </div>
                        <div className="card-body p-0">
                          <h5 className="text-primary d-inline mb-0">Prototype</h5>
                          <p className="text-light">We build the visual design and layout of your software. This interactive front-end prototype is great for demonstrations.</p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="card p-5 p-lg-8 bg-primary-soft border-0">
                        <div>
                          <span className="text-white p-2">
                            <i className="la la-dice-three ic-5x" />
                          </span>
                        </div>
                        <div className="card-body p-0">
                          <h5 className="text-primary d-inline mb-0">MVP</h5>
                          <p className="text-light">Now we've got something that you can go to market with! We've built the "minimum viable product" that allows you to learn what people will actually pay you for.</p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="card p-5 p-lg-8 bg-primary-soft border-0">
                        <div>
                          <span className="text-white p-2">
                            <i className="la la-dice-four ic-5x" />
                          </span>
                        </div>
                        <div className="card-body p-0">
                          <h5 className="text-primary d-inline mb-0">Deploy</h5>
                          <p className="text-light">When you go-live with your MVP, you need to start collecting data so you can decide what's next. Luckily, our platform has that built in from Day 1!</p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="card p-5 p-lg-8 bg-primary-soft border-0">
                        <div>
                          <span className="text-white p-2">
                            <i className="la la-dice-five ic-5x" />
                          </span>
                        </div>
                        <div className="card-body p-0">
                          <h5 className="text-primary d-inline mb-0">Scale</h5>
                          <p className="text-light">We use the learnings from your MVP to develop a product roadmap. Then we get to work helping you grow your business!</p>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                  <Link className="btn btn-primary" to="/mvpdevelopment">Learn more...</Link>
                </div>
              </div>
            </div>
          </section>
        );
    }
}

export default Approach;