/**
 *  Portfolio Project
 */
export default [
    {
        "id":1,
        "name":"Total Offender Management",
        "images":"01.jpg",
        "imageWebp":"01.webp",
        "url":"tom",
        "description":"<p>This was the project that introduced the founders of Whitewater Tech. A greenfield project starting in 2008, we have built industry-leading software for a client who offers community corrections services.</p><p>In the US, we work closely with the client to understand their business drivers and processes. A formal feature request system was implemented for the client's employees. All requests are analyzed and estimated before being prioritized by an internal review board. Once a feature receives the green light, it is entered onto the backlog for our development team consisting of four full-time developers and one full-time QA professional. Daily stand-up calls and frequent communication have enabled us to average a new release every other week for the past two years. User acceptance testing, release packaging, and deployment is managed in the US.</p><p>This application is primarily ASP.NET WebForms with SQL Server. We are currently engaged on a major update to a .NET Core API with a React UI. We have also implemented SQL Server Replication to an MS Azure platform and developed several ancillary web applications (.NET API w/ React UI) for related services.</p>",
        "tech": "<p>ASP.NET WebForms, C#, Entity Framework, MS SQL Server</p><p>jQuery, Javascript, Bootstrap, Telerik</p><p>SSRS, Topaz Systems (electronic signatures), Twilio (SMS messaging), Jenkins, Octopus Deploy</p>",
        "category":["Enterprise", "MS", "MVP"]
    },   
    {
        "id":2,
        "name":"CXM Dashboard",
        "images":"02.jpg",
        "imageWebp":"02.webp",
        "url":"cxm",
        "description":"<p>A startup client in the customer experience management industry required a method to consolidate all the various information that was being collected for their customers. For example, this data came from mystery shops, customer satisfaction surveys, operational audits, social media reviews, and other sources. Customers often expressed frustration having to check different sources manually, and our client wanted to solve this issue.</p><p>We built a consolidated reporting dashboard that allowed our client to import data from multiple vendor sources either manually or in an automated fashion. The portal allows our client's customers to setup their own users, manage access to various data types, and collect feedback on data points. A document sharing library was also included.</p>",
        "tech": "<p>MongoDB Stich, MongoDB</p><p>Angular, Javascript, Bootstrap</p><p>Amazon S3, Amazon SES</p>",
        "category":["Startups", "Angular", "AWS", "MVP"]
    },
    {
        "id":3,
        "name":"CourtLink",
        "images":"03.jpg",
        "imageWebp":"03.webp",
        "url":"courtlink",
        "description":"<p>Our client required an externally hosted website that could be used by court officials. The officials needed to track the progress of the offenders that had been sentenced to my client’s programs. Offender information was available in an internal system (Total Offender Management) but court officials could not be granted direct access to that system. Also, officials could not access information for offenders outside their jurisdiction.</p><p>A custom website was built with role based permissions that controlled access to offenders from each jurisdiction. The information is updated from the internal database at least daily. The system was built in a modular format to allow for easy addition of new datasets to the website. We continue working with the client on ongoing development.</p>",
        "tech": "<p>.NET Web API, C#, MS SQL Server Replication, Azure SQL</p><p>React, Javascript, Bootstrap</p><p>Azure Storage, Js.PDF</p>",
        "category":["Enterprise", "React", "MS", "Azure", "MVP"]
    },
    {
        "id":4,
        "name":"Schedappy",
        "images":"04.jpg",
        "imageWebp":"04.webp",
        "url":"schedappy",
        "description":"<p>In 2008 we were working with a client to improve their employee scheduling process. What worked fine for a handful of locations was quickly becoming unmanageable as they continued to grow. We evaluated the scheduling package from the POS vendor and found it cumbersome, inaccurate, and lacking in basic functions. At the time, there were no widely known cloud based solutions for scheduling. We saw an opportunity and built Schedappy to meet our customer’s needs as well as provide a business opportunity for ourselves.</p><p>Schedappy focuses on making employee scheduling simple. It doesn’t have every feature in the book, but the program runs well and we have a customer base that has stuck with us for years.</p><p>We are currently rebuilding this application in React with a REST API backend.",
        "tech": "<p>ASP.NET WebForms, C#, Entity Framework, MS SQL Server</p><p>jQuery, Javascript, Bootstrap</p><p>Azure VM, Twilio (SMS messaging)</p>",
        "category":["Startups", "MS", "React", "Azure", "MVP"]
    },
    {
        "id":5,
        "name":"Retail Loyalty Program",
        "images":"05.jpg",
        "imageWebp":"05.webp",
        "url":"loyalty",
        "description":"<p>A regional food service business competing in local markets against a dominant global corporation required a loyalty program to keep pace in the industry. Without the deep pockets of the mega-corporation we faced some technical limitations on what we could do. But we designed a program that matched the significant features of the competitor while giving our client an unrivaled platform to speak to their customer base.</p><p>We were responsible for building the technical components of the program. This consisted of:</p><ol class='light-font'><li>POS integration for reward and promotional redemption,</li><li>A website portal for loyalty member registration and account management,</li><li>Database processing of daily sales and membership information,</li><li>Platform for transactional and marketing campaign communications via both email and SMS,</li><li>An internal CRM tool for customer support,</li><li>Financial processing of member card reloads, and</li><li>Reporting and analysis tools.</li></ol>",
        "tech": "<p>ASP.NET MVC, MS SQL Server, MySQL</p><p>WordPress, Javascript, Bootstrap</p><p>Aloha QuickService POS, Twilio (SMS messaging), Mandrill (emails)</p>",
        "category":["Enterprise", "MS"]
    },
    {
        "id":6,
        "name":"Retail Inventory Management",
        "images":"06.jpg",
        "imageWebp":"06.webp",
        "url":"inv",
        "description":"<p>A growing retail operator required a solution that would simplify the inventory ordering process for their corporate operated stores and external accounts. Instead of managers calling their orders into home office and having someone there key the information into the distributor’s system manually, they wanted to streamline the process so that managers could submit the orders directly to the distributor.</p><p>We built an online ordering website that sync’d with the distributors warehouse management system. Products and prices were extracted from the WMS to the website, and orders submitted online were loaded directly into the WMS. We were also able to use the new up-to-the-minute product and pricing information to improve the accuracy of cost of sales reporting.</p><p>The original WMS was QuickBooks; the distributor changed WMS in 2014 and we were able to update the integration with no impact to end users. Currently, we are in the process of using the order information as one of the inputs to a perpetual inventory calculation.</p>",
        "tech": "<p>ASP.NET WebForms, WMS REST API, MS SQL Server</p><p>jQuery, Javascript</p><p>QuickBooks Web Connector, Mandrill (emails)</p>",
        "category":["Enterprise", "MS"]
    },
    {
        "id":7,
        "name":"Medical Clinic Management",
        "images":"07.jpg",
        "imageWebp":"07.webp",
        "url":"mcm",
        "description":"<p>A medical clinic needed to provide provide case workers an easy way to track patients through the discharge and post discharge process. The application we built included intake forms, interactive dashboards, patient medical history detail, schedule and appointment management, and individualized and bulk notifications.</p>",
        "tech": "<p>ASP.NET MVC, .NET Web API, C#, MS SQL Server</p><p>Angular, jQuery, Javascript</p><p>Google Calendar, Twilio (SMS messaging), SendGrid (email)</p>",
        "category":["Startups", "Angular", "MS"]
    },  
    {
        "id":8,
        "name":"Blood Bank Supply Management",
        "images":"08.jpg",
        "imageWebp":"08.webp",
        "url":"bb",
        "description":"<p>A local entrepreneur was evaluating an opportunity to improve supply chain management for independently operated blood banks. The client described the supply chain and asked us to develop a dashboard mockup that he could use during conversations with potential customers. Unfortunately, this application was never built.</p>",
        "category":["Startups", "MVP"]
    },
    {
        "id":9,
        "name":"PowerBI Dashboards",
        "images":"09.jpg",
        "imageWebp":"09.webp",
        "url":"powerbi",
        "description":"<p>A retail entrepreneur needed a method of communicating key performance metrics with her management team. We built a process to extract sales transactions from the POS and summarize the data in a cloud database. A PowerBI dashboard was built to display all their key measures.</p>",
        "tech": "<p>MS SQL Server, PowerBI, Windows scripting</p><p>Azure SQL, Catapult Retail POS</p>",
        "category":["Analytics","Azure"]
    },
    {
        "id":10,
        "name":"Service Center Management",
        "images":"10.png",
        "imageWebp":"10.webp",
        "url":"scm",
        "description":"<p>We were asked to build software that would help an HVAC equipment repair company track their projects and manage their workforce. The application we developed included performance dashboards, inspection and test checklists, a customer portal, dynamic questionnaires, audit and compliance reports, and more.</p>",
        "tech":"<p>ASP.NET Core 2.2, Entity Framework, .NET Web API, MySQL</p><p>Angular7, Javascript, HTML5, CSS3, SCSS, LESS, SASS, Bootstrap</p><p>Android & iOS apps (native)</p>",
        "category":["Enterprise", "MS", "Angular"]
    },
    {
        "id":11,
        "name":"EdTech Startup Pilot",
        "images":"11.jpg",
        "imageWebp":"11.webp",
        "url":"edtech",
        "description":"<p>The winner of a TechStars startup weekend wanted to run a pilot project further testing his business concept. We quickly, and cost effectively, built a pilot application that allowed him to gauge the interest level of a key constituency - college students.</p>",
        "tech": "<p>.NET Core, .NET Web API, MS SQL Server</p><p>Angular, Javascript, Bootstrap</p><p>Azure SQL, Azure App Services, SendGrid (email)</p>",
        "category":["Startups", "React", "MS", "Azure", "MVP"]
    },
    {
        "id":12,
        "name":"Application Performance Monitoring",
        "images":"12.jpg",
        "imageWebp":"12.webp",
        "url":"appmon",
        "description":"<p>The solutions we've built for our clients have hundreds of tasks that run automatically anywhere from once a minute to monthly. At first we had tasks send an “all good” email in addition to any notifications generated from error conditions. This quickly got out of hand and required time every day filtering through the emails to find any issues.</p><p>We built a solution based on a heartbeat concept. Tasks are setup to send our system a heartbeat notification each time they complete successfully. We have monitors defined with parameters such as how frequently we should get a heartbeat. Now we just receive notifications whenever a process has not sent a heartbeat when expected. This might be due to a server outage, internet disruption, or application error.</p>",
        "tech": "<p>ASP.NET WebForms, C#, REST API, MS SQL Server, Windows scripting</p>",
        "category":["MS"]
    },
    {
        "id":13,
        "name":"Scheduling Tools for Offenders",
        "images":"13.jpg",
        "imageWebp":"13.webp",
        "url":"tomrex",
        "description":"<p>We built a supplemental application for Total Offender Management that support bi-directional data exchange with the core system. This cloud-hosted application allows offenders to submit their schedules and review their financial transactions thus reducing paperwork and questions for staff. Also available are announcements, a document library, and links to relevant web resources.</p>",
        "tech": "<p>.NET Web API, C#, MS SQL Server, Azure SQL</p><p>React, Javascript, Bootstrap</p><p>Azure Storage",
        "category":["Enterprise", "React", "MS", "Azure"]
    }
]