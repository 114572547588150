import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

class MVPDevelopment extends Component {
    render() {
      return (
        <Fragment>
          <Helmet>
            <title>Whitewater Tech - Partnering with Accelerators and Incubators</title>
            <meta name="description" content="We love accelerators and incubators! Whitewater has special programs that complement their services and help you get your startup off the ground faster." />
          </Helmet>
          <h1>Accelerators and Incubators</h1>
          <div className="card border-0 bg-transparent">
            <p>As a startup founder, there are many resources right in your local community that can help you get your idea off the ground. Accelerators and incubators offer tools that help you think through your idea, build a business model, develop financial projections, and get feedback from a broad network of experience professionals.</p>
            <p>Whitewater Tech partners with incubators and accelerators to help startups when they are ready to start building. Our services are tailored to what you need at any step of the process.</p>
            <div className="row align-items-center mb-8">
              <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                  <div className="card bg-primary shadow border-0">
                      <div className="card-body py-11 px-6">
                          <h5 className="text-light">Ideation</h5>
                          <p className="text-light">Share your idea with us and we will provide feedback on its technical feasibility. Then we’ll develop wireframes and a prototype so you can start visualizing your software and demonstrating it to others.</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                  <div className="card bg-primary shadow border-0">
                      <div className="card-body py-11 px-6">
                          <h5 className="text-light">MVP</h5>
                          <p className="text-light">Whitewater can help you identify the most critical portions of your service so that they are included in the MVP. We’ll get it built and launched quickly, so you can start getting feedback and iterating.</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-4">
                  <div className="card bg-primary shadow border-0">
                      <div className="card-body py-11 px-6">
                          <h5 className="text-light">Scaling</h5>
                          <p className="text-light">After you launch, you will need a partner that can quickly develop and deploy features according to a prioritized roadmap. We can help you maintain that roadmap and keep things moving.</p>
                      </div>
                  </div>
              </div>
          </div>
            <h5>What You Need</h5>
            <p>Many startups feature several co-founders with diverse skillsets. If you already have a technical co-founder, then they can use our offshore developers to accelerate their work. But if you don’t have a technical co-founder, then you are going to love our onshore-offshore model. With an American project manager coordinating the work of a team of offshore developers, it will be like you’ve got your own CTO and your own dedicated programming team!</p>
            <h5>Startup Program</h5>
            <p>Whitewater recognizes the value added by formal accelerator and incubator programs. We have a special 20% discount offer for startups enrolled with those programs. This discount can be applied to the costs of prototyping and MVP development as long as you are enrolled in a formal startup program. Additionally, many of our official accelerator partners have grant programs that can reduce your development cost even further.</p>
            <h5>Partnering with Whitewater Tech</h5>
            <p>If you are an accelerator or incubator, we would love to talk about how partnering with Whitewater can benefit your entrepreneurs. Besides programming services, we are happy to offer technical guidance and insights to startup founders at any time. Use the contact info or form below to get in touch, and let's figure out how we can best help your organization.</p>
            <div />
          </div>
        </Fragment>
      )
    }
}

export default MVPDevelopment;