import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
    render() {
        return (
            <div>
                <section id="services">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-lg-6 order-lg-1 mb-8 mb-lg-0">
                              <picture>
                                <source type="image/webp" srcset={require(`../../../assets/images/about/03.webp`)} />
                                <source type="image/jpeg" srcset={require(`../../../assets/images/about/03.png`)} />
                                <img src={require(`../../../assets/images/about/03.png`)} alt="Graphic of your offshore development team" className="img-fluid" />
                              </picture>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <div className="mb-5"> <span className="h6 text-primary">
                                    About Whitewater Tech
                                    </span>
                                    <h3 className="mt-3 font-w-5">Rapid development services</h3>
                                    <p className="lead">Our platform allows us to build and deploy your prototype, MVP, or other software project at high speed.</p>
                                </div>
                                <div className>
                                    <div className="mb-3">
                                        <div className="d-flex align-items-start">
                                            <div className="badge-primary-soft rounded p-1">
                                                <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </svg>
                                            </div>
                                            <p className="mb-0 ml-3">Our pre-built application templates give us a head start on all new projects.</p>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="d-flex align-items-start">
                                            <div className="badge-primary-soft rounded p-1">
                                                <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </svg>
                                            </div>
                                            <p className="mb-0 ml-3">Everything from authentication to analytics built-in and ready to go. Use our standard services or we'll connect to your favorites.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-start">
                                            <div className="badge-primary-soft rounded p-1">
                                                <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                    <polyline points="20 6 9 17 4 12" />
                                                </svg>
                                            </div>
                                            <p className="mb-0 ml-3">Built for low initial cost and scalable for growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row  justify-content-between">
                            <div className="col-12 col-lg-6 col-xl-5">
                                <div> 
                                    <h3 className="font-w-5">Our onshore-offshore hybrid model delivers high quality results faster</h3>
                                    <p className="lead mb-0">US-based business analysis and project management; global partners providing expert programming and design. We are literally working around the clock on your project.</p>
                                </div> 
                                <Link className="btn btn-primary" to="/onshoreoffshore">Learn more...</Link>
                            </div>
                            <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                                <div className="d-flex justify-content-between mb-5">
                                    <div className="mr-3">
                                        <span className="badge badge-primary-soft p-2">
                                            <i className="la la-users ic-5x" />
                                        </span>
                                    </div>
                                    <div>
                                        <h5 className="mb-2">Coordination</h5>
                                        <p className="mb-0">Standardized project management tools and practices keeps everything working smoothly.</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-5">
                                    <div className="mr-3">
                                        <span className="badge badge-primary-soft p-2">
                                            <i className="la la-comments ic-5x" />
                                        </span>
                                    </div>
                                    <div>
                                        <h5 className="mb-2">Communication</h5>
                                        <p className="mb-0">A regular meeting cadence, centralized project documentation, and an accessible team ensure we're always on the same page.</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="mr-3">
                                        <span className="badge badge-primary-soft p-2">
                                            <i className="la la-landmark ic-5x" />
                                        </span>
                                    </div>
                                    <div>
                                        <h5 className="mb-2">Compliance</h5>
                                        <p className="mb-0">From code repositories to data transmission and storage, your systems are built with security top-of-mind.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default About;
