import React, { Fragment, lazy, Suspense } from 'react';
import Helmet from 'react-helmet';
import { Switch, Route ,withRouter} from 'react-router-dom';
import Home from './pages/home';
import InnerContent from './pages/innercontent';
import Projects from './pages/projects';
import Route404 from './pages/404';

const Login = lazy(() => import('./pages/login'))
const PrivacyPolicy = lazy(() => import('./pages/privacypolicy'))
const TermCondition = lazy(() => import('./pages/termcondition'))

import './App.css';
import './Vendor.js';

import { connect } from 'react-redux';
import Scrolltop from './layout/back-to-top';

class App extends React.Component {
  constructor(props) {
    super(props)
  }
  UNSAFE_componentWillMount() { }
  render() {
    const { location } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>Whitewater Tech - Your Local Offshore Development Partner</title>
          <meta name="description" content="Whitewater Tech combines onshore project management with expert offshore developers so your MVP or software project gets built faster." />
        </Helmet>
        <div className="page-wrapper">
          <Suspense fallback={Home}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/terms" component={TermCondition} />
              <Route exact path="/projects/:content"  component={Projects} />
              <Route exact path="/mvpdevelopment" render={(props) => <InnerContent {...props} content="mvpdevelopment" />} />
              <Route exact path="/onshoreoffshore" render={(props) => <InnerContent {...props} content="onshoreoffshore" />} />
              <Route exact path="/accelerators" render={(props) => <InnerContent {...props} content="accelerators" />} />
              <Route component={Route404} />
            </Switch>
          </Suspense>
          <Scrolltop />
        </div>
      </Fragment>
    );
  }
}

const AppMapStateToProps = state => {
  return { };
};

const AppMapDispatchToProps = dispatch => {
  return { };
};

export default connect(AppMapStateToProps,AppMapDispatchToProps)(withRouter(App))

