import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import InnerPage from '../../layout/innerpage';

class PageNotFound extends Component {
    constructor(props) {
        super(props)
      }
      componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
          <div>
            {/*header start*/}
            <Header />
            {/*header end*/}
            <InnerPage />
            {/*body content start*/}
            <div className="page-content">
              <section className="fullscreen-banner p-0">
                  <div className="container h-100">
                      <div className="row h-100 mb-6">
                          <div className="col-lg-6 text-center h-100 d-flex align-items-center">
                            <div className="w-100">
                              <picture>
                                <source type="image/webp" srcset={require(`../../assets/images/404.webp`)} />
                                <source type="image/jpeg" srcset={require(`../../assets/images/404.png`)} />
                                <img className="img-fluid d-inline mb-5" src={require(`../../assets/images/404.png`)} alt="" />
                              </picture>
                            </div>
                          </div>
                          <div className="col-lg-6 text-center h-100 align-items-center">
                              <h2>Oops! Page Not Found</h2>
                              <h6 className="mt-6">You’re either misspelling the URL or requesting a page that's no longer here.</h6>
                              <Link className="btn btn-primary mt-6" to="/">Back To Home Page</Link>
                          </div>
                      </div>
                  </div>
              </section>
            </div>
            {/*footer start*/}
            <Footer />
            {/*footer end*/}
          </div>
        );
    }
}

export default PageNotFound;