/**
 *  Main Menu Json
 */
export default [
   {
      "menu_title": "Home",
      "path": "/"
   },
   {
    "menu_title": "Services",
    "type": "subMenu",
    "child_routes": [
      {
        "menu_title": "Service Model",
        "path": "/#services",
        "child_routes": null
      },
      {
        "menu_title": "Onshore-Offshore Development",
        "path": "/onshoreoffshore",
        "child_routes": null
      }
    ],
 },
 {
  "menu_title": "Startups",
  "type": "subMenu",
  "child_routes": [
    {
      "menu_title": "Startup Process",
      "path": "/#startups",
      "child_routes": null
    },
    {
      "menu_title": "MVP Development",
      "path": "/mvpdevelopment",
      "child_routes": null
    },
    {
      "menu_title": "Accelerators and Incubators",
      "path": "/accelerators",
      "child_routes": null
    }
  ],
},
{
      "menu_title": "Portfolio",
      "path": "/#portfolio"
   },
   {
      "menu_title": "FAQ",
      "path": "/#faq",
   },
   {
      "menu_title": "Contact",
      "path": "/#contact",
   }
]
