import React, { Component, Fragment } from 'react';
import ProjectPortfolio from '../../../api/ProjectPortfolio.js';
import ProjectCategories from '../../../api/ProjectCategories.js';
import Lightbox from 'react-modal-image';
import ProjectFooter from './project-footer';

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PortfolioList: ProjectPortfolio,
            uniqueCategory: ProjectCategories,
            PortfolioDisplay: ProjectPortfolio,
            listProject:[],
            isOpen:false
        }
        this.HandleLightbox=this.HandleLightbox.bind(this);
    }
    componentDidMount() {
    }
    HandleLightbox() 
    {
        this.setState({ 
                ...this.state,
                isOpen: !this.state.isOpen 
        });
    };
    onCategoryClick(cate) {
        var removeelems = document.getElementsByClassName("portfolio-set");
        [].forEach.call(removeelems, function (el) {
            el.classList.remove('is-checked');
        });
        if (cate === "all") {
            this.setState({
                ...this.state,
                PortfolioDisplay: this.state.PortfolioList,
                listProject:this.state.PortfolioList
            })
            document.querySelector(".all").classList.add("is-checked");
        }
        else {
            let CatgoryValue = this.state.PortfolioList.filter((portfolio) => {
                let categoryMatch;
                if (portfolio.category)
                    categoryMatch = portfolio.category.some(category => cate.includes(category))
                else
                    categoryMatch = true
                return (categoryMatch)
            })

            this.setState({
                ...this.state,
                PortfolioDisplay: CatgoryValue,
                listProject:CatgoryValue
            })
            document.querySelector("."+cate).classList.add("is-checked");
        }
    }
    render() {
        const { PortfolioList, uniqueCategory,totalPagesdata,listProject, PortfolioDisplay } = this.state;
        
        return (
          <Fragment>
            <div id="portfolio" className="mb-10"></div>
            <div className="mb-5 justify-content-center text-center">
              <h2>Portfolio</h2>
            </div>
            <section className="p-0">
              <div className="container">
                  <div className="row">
                      <div className="col text-center">
                          {this.state.uniqueCategory.length > 0 ?
                              <div className="portfolio-filter">
                                  <button data-filter className="portfolio-set all is-checked mb-3 mb-sm-0" value="all" onClick={(e) => { this.onCategoryClick('all') }} >All Work</button>
                                  {this.state.uniqueCategory.map((category, index) =>
                                      <button data-filter=".cat1" className={"portfolio-set mb-3 mb-sm-0 " + category} value={category} onClick={(e) => { this.onCategoryClick(category) }} >{category}</button>
                                  )}

                              </div>
                              :
                              null
                          }

                      </div>
                  </div>
              </div>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12 col-md-12">
                          {this.state.PortfolioDisplay.length > 0 ?
                              <div className="grid columns-3 row popup-gallery">
                                  <div className="grid-sizer" />
                                  {this.state.PortfolioDisplay.map((port, index) =>
                                      <div className="grid-item col-lg-4 col-md-6 mb-5 cat3">
                                          <div className="portfolio-item position-relative overflow-hidden">
                                              <Lightbox
                                                  className="img-center w-100"
                                                  small={require(`../../../assets/images/portfolio/${port.images}`)}
                                                  smallSrcSet={require(`../../../assets/images/portfolio/${port.imageWebp}`)}
                                                  medium={require(`../../../assets/images/portfolio/large/${port.images}`)}
                                                  alt={port.name}
                                                  onClose={this.HandleLightbox}
                                                  hideDownload="true"
                                                  hideZoom="true"
                                              />
                                              <ProjectFooter
                                                  projectCategories={port.category} 
                                                  projectTitle={port.name} 
                                                  projectUrl={port.url}
                                              />
                                          </div>
                                      </div>
                                  )}
                              </div>
                              :
                              <div className="grid columns-3 row popup-gallery">
                                  <p>Please try searching with other words.</p>
                              </div>
                          }
                      </div>
                  </div>
              </div>
            </section>
          </Fragment>
        );
    }
}

export default ProjectList;