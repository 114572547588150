import React, { Component, Fragment } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';



class FaqList extends Component {

    render() {
        return (
          <Fragment>
            <div id="faq" className="mb-10"></div>
            <div className="mb-5 justify-content-center text-center">
              <h2>Frequently Asked Questions</h2>
            </div>
            <section className="p-0">
              <div className="container">
                <div className="row justify-content-between">      
                  <div className="col-12 col-lg-6 col-xl-5">
                    <Accordion>
                        <AccordionItem className="accordion__item mb-2" expanded >
                            <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                                <h6 className="mb-0">
                                    <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true">Do you only work with startups?</a>
                                </h6>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <div className="text-muted">No! We work with companies of all sizes in all stages. We offer services specifically tailored to startups, but our development model can work for everyone.</div>
                            </AccordionItemBody>
                        </AccordionItem>

                        <AccordionItem className="accordion__item mb-2">
                            <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                                <h6 className="mb-0">
                                    <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse2">Will I have to be on calls late at night?</a>
                                </h6>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <div className="text-muted">Not at all. In most cases, our US-based PM works directly with the team usually early in the morning. When necessary, the entire team can schedule meetings to fit your schedule.</div>
                            </AccordionItemBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                                <h6 className="mb-0">
                                    <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse3">Who owns the software you develop?</a>
                                </h6>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <div className="text-muted">You own everything we develop for you. Everything.</div>
                            </AccordionItemBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                                <h6 className="mb-0">
                                    <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse3">How do I pay the offshore team?</a>
                                </h6>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <div className="text-muted">We take care of all payments to our offshore team. If you ever want to send a special thank-you to them, we can definitely facilitate that.</div>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                  </div>
                  <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                    <picture>
                      <source type="image/webp" srcset={require(`../../../assets/images/about/01.webp`)} />
                      <source type="image/jpeg" srcset={require(`../../../assets/images/about/01.png`)} />
                      <img className="img-fluid mb-8" src={require(`../../../assets/images/about/01.png`)} alt="Frequently Asked Questions" />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
          </Fragment>
        );
    }
}

export default FaqList;