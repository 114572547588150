import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import InnerPage from '../../layout/innerpage';
import MVPDevelopment from './content/mvp_development.js';
import OnshoreOffshore from './content/onshore_offshore_model.js';
import Accelerators from './content/accelerators_and_incubators.js';

class Content extends Component {
    constructor(props) {
      super(props);
      this.state = {

      }
    }
    components = {
      mvpdevelopment: [MVPDevelopment, '/#startups'],
      onshoreoffshore: [OnshoreOffshore, '/#services'],
      accelerators: [Accelerators, '/']
    };
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const ContentComponent = this.components[this.props.content][0];
    
        const handleBackClick = (evt) => {
          if (this.props.history.length > 2) {
            evt.preventDefault();
            this.props.history.goBack();
          }
        }

        return (
            <div>
              {/*header start*/}
              <Header />
              {/*header end*/}
              <InnerPage />
              {/*body content start*/}
              <div className="page-content">
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                              <ContentComponent />
                              <Link className="btn btn-primary mt-6" to={this.components[this.props.content][1]} onClick={handleBackClick}>Back</Link>
                            </div>
                        </div>
                    </div>
                </section>
              </div>
            {/*body content end*/}
            {/*footer start*/}
            <Footer />
            {/*footer end*/}
            </div>
        );
    }
}

export default Content;