import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

class OnshoreOffshore extends Component {
    render() {
      return (
        <Fragment>
          <Helmet>
            <title>Whitewater Tech - Onshore-Offshore Software Development Services</title>
            <meta name="description" content="Our onshore-offshore model has American project managers and global engineers. Your own global software development team working just like they're on-site." />
          </Helmet>
          <h1>The Onshore-Offshore Hybrid Development Model</h1>
          <div className="card border-0 bg-transparent">
            <p>Companies have been using offshore software development services for many years. Drawn by the potential of lower costs, they have shipped their development work overseas looking to move faster and cheaper than their competition. The results have been inconsistent at best. Why?</p>
            <h5>Communication</h5>
            <p>Offshore firms often claim a high degree of English fluency, and it’s been our experience that their claims are not unfounded. But the nuances and norms of communication are often trickier to navigate when speaking with foreign English speakers. Communicating effectively often requires a degree of patience and accommodation that develops over time working with the same people.</p>
            <h5>Coordination</h5>
            <p>It’s hard enough to coordinate a team’s efforts when you’re all working in the same building. The recent acceleration of remote work arrangements has improved our ability to work asynchronously. Adding up to 12 hours of time difference definitely does not make things easier! There has to be some regular schedule overlap, and many people expect the offshore team to stay up late to make that happen.</p>
            <h5>Collaboration</h5>
            <p>Many offshore projects are established under the premise of “We’re going to tell you exactly what we want and you give that to us.” As a result, many offshore software development firms operate under that mentality. You will get exactly what you ask for, and if you have missed anything in your requirements or have expected the team to fill in the blanks, you are going to be disappointed. Establishing a truly collaborative relationship takes time, perseverance, and the willingness to fail at times in order to establish a high level of trust amongst the team.</p>
            <h5>A Broken Model</h5>
            <p>To be sure, there are many examples of producing quality software working with offshore programming teams. The most successful projects have some common characteristics:</p>
            <ul className="light-font">
              <li>A project manager who has prior experience working directly with the specific offshore team,</li>
              <li>A willingness to adapt personal schedules to maximize productive work time,</li>
              <li>A team that continuously improves their project methodology over time, </li>
              <li>A period of co-location during project initiation, and</li>
              <li>A focus on collaboration and team development.</li>
            </ul>
            <p>Large companies can certainly put the resources into selecting an offshore team, co-locating staff with that team for a period of time, and building that relationship over many years. But, that’s an unusual situation. At best, the typical company looking to partner with an offshore software development firm will meet two of those four success criteria. It’s easy to see that the offshore software development experience is fundamentally flawed.</p>
            <h5>A New Model</h5>
            <p>Given these challenges, a new model of outsourced software development is delivering great results. The onshore-offshore hybrid model combines the cost and time efficiencies of offshore development with the reliability and quality of an onshore team. </p>
            <ul className="light-font">
              <li>A project manager who has prior experience working directly with the specific offshore team,</li>
              <li>Formalized long-term partnerships between the onshore and offshore teams,</li>
              <li>Offshore team built with a focus on expertise and not simply cost-minimization,</li>
              <li>Highly refined software development methodologies, and</li>
              <li>Transparency in project status and billing.</li>
            </ul>
            <p>The firm offering an onshore-offshore hybrid model is flexible enough to help companies large and small with their projects through a variety of operating modes.</p>
            <h5>Startups vs Enterprise</h5>
            <p>A startup is motivated to get to market as quickly as possible. They need to validate their idea, so the delivery of a Minimum Viable Product (MVP) that is built quickly and cost-efficiently is the primary goal. The founders have no time to spend worrying about software development methodologies or best-practice engineering approaches. Their partner needs to bring those things to the table so the startup can focus on learning as much as possible with their MVP.</p>
            <p>On the other hand, established enterprises have a better idea of what they need to get where they want to go. They may even have internal IT teams with their own processes and standards. Whether building a new application from scratch or enhancing an existing application, an enterprise project often has higher legacy-application and data integration requirements, bureaucratic processes, and other organizational complications that create challenges for the team. To fit into this scenario, the external software development firm needs a high degree of flexibility and adaptability in their own practices.</p>
            <p>The onshore-offshore hybrid model serves both startups and established enterprises well. For startups, the onshore resources often become trusted advisors to the founders as they help the founders navigate through many technical challenges; for enterprises, the onshore resources often focus on business understanding and then helping the offshore team adapt for maximum success. In both cases, the onshore resources act as facilitators and “translators” for the client, while the offshore managers focus on compiling and managing a team of experts capable of delivering the product.</p>
            <div />
            <h5>Complete Team vs Staff Augmentation</h5>
            <p>Both startups and established companies often find themselves needing to build a team for a project. It would be great if they could snap their fingers and have a fully capable team onboarded and ready to start working. No HR paperwork, no interviews to conduct, no computers to procure, no desks to setup, etc. In these cases, an onshore-offshore hybrid firm can embed an onshore project manager to learn the business requirements and define the team needed. The offshore managers assemble the team, and then start daily communications with the project manager to get up to speed. Your new team is operating, producing, and optimizing within a few weeks.</p>
            <p>But sometimes you just need an extra set of hands. Maybe you’ve got an application team that has a special project where an extra programmer could be useful. Since the onshore-offshore firm is always recruiting and hiring talent, they have a ready supply of expert programmers available to jump onto your team. These assignments are usually reserved for senior programmers who have demonstrated the ability to quickly engage and work independently. The onshore resources in this case act as more of a relationship manager helping with any “translation” issues.</p>
            <h5>Whitewater Tech</h5>
            <p>We’ve built Whitewater Tech as an onshore-offshore hybrid firm because we’ve been through this process many times. We know how to work with our clients to deliver high-quality software, and how to operate effectively as an onshore-offshore team. We look forward to the opportunity to help make your project a success.</p>
          </div>
        </Fragment>
      )
    }
}

export default OnshoreOffshore;