import React, { Component } from 'react';

class InnerPage extends Component {
    render() {
        return (
            <section className="custom-pt-0 custom-pb-2 bg-primary position-relative parallaxie">
            <div className="shape-1 bottom" style={{ height: '150px', overflow: 'hidden' }}>
              <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
              </svg>
            </div>
          </section>
        
        );
    }
}

export default InnerPage;