import React, { Component } from 'react';

class Herosection extends Component {
    render() {
        return (
            <section className="custom-pt-1 custom-pb-3 bg-primary position-relative parallaxie">
            <div className="container">
              <div className="row align-items-center">
                  <div className="col-12 col-lg-5 col-xl-5 mr-auto mb-8 mb-lg-0">
                      {/* Image */}
                      <picture>
                        <source type="image/webp" srcset={require(`../../../assets/images/hero/02.webp`)} />
                        <source type="image/jpeg" srcset={require(`../../../assets/images/hero/02.png`)} />
                        <img src={require(`../../../assets/images/hero/02.png`)} className="img-fluid" alt="Graphic of an offshore software developer" />
                      </picture>
                      
                  </div>
                  <div className="col-12 col-lg-7 col-xl-6">
                      {/* Heading */}
                      <h1 className="display-4 text-white">
                      Build your dream technology with <span className="font-weight-bold">Whitewater Tech</span>
                      </h1>
                      {/* Text */}
                      <p className="lead text-light">Experience the benefits that only an expert onshore-offshore team can deliver.</p>
                      {/* Buttons */} 
                      <a href="#services" class="btn btn-outline-light mr-1">
                          Learn More
                      </a>
                  </div>
              </div>
            </div>
            {/* / .container */}
            <div className="shape-1 bottom" style={{ height: '250px', overflow: 'hidden' }}>
              <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
              </svg>
            </div>
          </section>
        
        );
    }
}

export default Herosection;