import React from 'react';
import { Link } from 'react-router-dom';

const ProjectFooter = (props) => {
  const {
    projectCategories,
    projectTitle,
    projectUrl
  } = props;

  return (
    <div>
      <Link to={`/projects/${projectUrl}`}>
        <div className="portfolio-title d-flex justify-content-between align-items-center">
            <div>
                <small className="text-light mb-2">
                    {projectCategories ?
                        <span>
                            {projectCategories.map((category, i, arr) =>
                                <span style={{ color: 'white' }}>{category}  {i != (arr.length - 1) ? '|' : ''} </span>
                            )}
                        </span>
                        :
                        null
                    }
                </small>
                <h6 className="text-white">{projectTitle}</h6>
            </div>
            <div className="popup-img h2 text-white"> <i className="la la-plus" /></div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectFooter;