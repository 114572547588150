import React from 'react';
import navLinks from '../../api/NavLinks';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import logo from '../../assets/images/logo_transparent.png';


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            visible: false,
            loader: false,
            headername: window.location.pathname
        }
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    handleClick(event) {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            this.setState({
                visible: true
            });
        }
        else {
            this.setState({
                visible: false
            });
        }

    }

    render() {
        const { visible, headername } = this.state;
        // console.log('okay',headername)
        if (this.state.loader == true) {
            setTimeout(function () {
                this.setState({ loader: false });
            }.bind(this), 2000);
        }
        return (
            <header className="site-header navbar-dark">
                {(this.state.loader == false) ?
                    <div id="header-wrap" className={` ${"position-absolute w-100 z-index-1"} ${(visible) ? "fixed-header " : ""}`}>
                        <div className="container">
                            <div className="row">
                                {/*menu start*/}
                                <div className="col d-flex align-items-center justify-content-between">
                                    <Link className="navbar-brand logo text-white  h2 mb-0" to="/">
                                        <img src={logo} alt="Whitewater Tech logo" className="mr-2" />
                                        <span className="font-weight-bold">Whitewater</span> Tech
                                    </Link>
                                    {/* <Navbar className="navbar-expand-lg navbar-light ml-auto">
                                        <NavbarToggler onClick={this.toggle} />
                                        <Collapse isOpen={this.state.isOpen} className=" navbar-collapse" navbar>
                                            <Navbar className="navbar-expand-lg navbar-light ml-auto">
                                                <Nav className="mr-auto" navbar>
                                                    {navLinks.map((navLink, index) => (
                                                        <NavItem>
                                                            <NavLink href={navLink.path}> {navLink.menu_title}</NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>
                                            </Navbar>
                                        </Collapse>
                                    </Navbar> */}
                                    <Navbar className="navbar-expand-lg navbar-light ml-auto">
                                        <NavbarToggler onClick={this.toggle} />
                                        <Collapse isOpen={this.state.isOpen} className=" navbar-collapse" navbar>
                                            <Nav className="ml-auto" navbar>
                                                {navLinks.map((navLink, index) => (
                                                    (navLink.type && navLink.type === 'subMenu') ?
                                                        <UncontrolledDropdown nav inNavbar key={index}>
                                                            <DropdownToggle nav caret >
                                                                {navLink.menu_title}
                                                            </DropdownToggle>
                                                            <DropdownMenu id={`submenu_${index}`} className="childsubmenu">
                                                                {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                    <DropdownItem  key={index} tag="a" href={subNavLink.path}>{subNavLink.menu_title}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        :
                                                        <NavItem>
                                                            <NavLink href={navLink.path}> {navLink.menu_title}</NavLink>
                                                        </NavItem>
                                                ))}
                                            </Nav>
                                        </Collapse>
                                    </Navbar>
                                    {/* <Link className="btn btn-light ml-8 d-none d-lg-block" to="/login">Login</Link> */}
                                </div>
                                {/*menu end*/}
                            </div>
                        </div>
                    </div>
                    :
                    <div id="ht-preloader">
                        <div className="loader clear-loader">
                            <span />
                            <p>Whitewater Tech</p>
                        </div>
                    </div>
                }
            </header>

        );
    }
}

export default Header;